import * as React from 'react'
import Layout from '../../components/layout'
import { Link, graphql } from 'gatsby'
import * as style from './tag.module.css'



const Tags = ({ data }) => {
  return (
    <Layout pageTitle="Tags">
      <ul>
      {
        data.allMdx.distinct.map((tag) => (
          <p>{tag}</p>
        ))
      }
      </ul>
    </Layout>
  )
}

export const query = graphql`
query {
    allMdx(filter: {}) {
      distinct(field: frontmatter___tags)
    }
  }
`

export default Tags